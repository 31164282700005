<template>
  <section>
    <p>
      In the water treatment industry, sterilization and disinfection are
      pivotal and indispensable links, among which the most representative means
      of disinfection is the method of adding chlorine-containing reagent
      through a chlorine metering pump, which has been widely used in various
      types of disinfection equipment in water plants.
    </p>
    <p>
      Chlorine-containing disinfectant is a disinfectant that dissolves in water
      and produces hypochlorous acid, which could kill many microorganisms.
      Common disinfectants include inorganic chlorine compounds, such as sodium
      hypochlorite, calcium hypochlorite, trisodium phosphate chloride, and
      chloramines such as sodium dichloroisocyanurate, trichloroisocyanuric
      acid, ammonium chloride. There are many advantages to using chlorination
      disinfection. It costs lower in each unit water treatment and can maintain
      a certain amount of residual chlorine for a long time, with the ability of
      continuous disinfection. Chlorine-containing disinfectant is a relatively
      mature disinfection method.
    </p>
    <h2>Chlorine Doser Pumps Used In Odium Hypochlorite</h2>
    <p>
      The most important way of sodium hypochlorite disinfection and
      sterilization is to form strong oxidizing hypochlorous acid through its
      hydrolysis, which is further decomposed to form new ecological oxygen [O].
      The strong oxidation of new ecological oxygen causes the protein
      denaturation of bacteria and viruses that kill the pathogenic
      microorganisms. The higher the concentration of sodium hypochlorite, the
      stronger the bactericidal action. At the same time, chlorine ions produced
      by hypochlorous acid can significantly change the osmotic pressure of
      bacteria and virion, making their cells inactive and die. Most
      importantly, it is able to disinfect for a period of time that no other
      disinfectant can achieve.
    </p>
    <p>
      Therefore, sodium hypochlorite generator is very popular that can
      integrate the components of dissolved salt, dilute saltwater,
      electrolysis, liquid chlorine dosing pump, adding the solution, control,
      and other units into an integral pry block for easy movement and
      installation. The equipment is equipped with an intelligent control
      system. The chlorine dosing system pumps can help to realize the automatic
      production and dosing of sodium hypochlorite disinfectant.
    </p>
    <h2>Chlorine Doser Pumps Used In Calcium Hypochlorite</h2>
    <p>
      It is also known as bleaching powder and bleaching powder essence. The
      effective chlorine content of bleaching powder is higher than 20% while
      the bleaching powder extraction could be 56%~60%. The calcium hypochlorite
      could be easily dissolved in water. They are added in the automatic
      chlorine dosing system with chlorine pump water treatment for the
      industrial wastewater flocculation settlement, precipitation clarification
      treatment, such as steel works wastewater, electroplating plant
      wastewater, metallurgical wastewater, coal washing wastewater and other
      sewage treatment, sludge dewatering, can also be used for drinking water
      clarification and purification treatment.
    </p>
    <h2>Chlorine Doser Pumps Used In Hypochlorous acid water</h2>
    <p>
      Hypochlorous acid water is an aqueous solution that contains stable
      hypochlorous acid molecules. It is a new kind of highly effective
      disinfectant. It is characterized by a wide sterilization spectrum, strong
      killing power, high safety, and good environmental protection. In the
      chlorine pump water treatment field, they are applied to the disinfection
      of medical organizations and water supply facilities.
    </p>
    <h2>Chlorine Doser Pumps Used In Sodium Dichloroisocyanurate</h2>
    <p>
      The effective chlorine content is higher than 55%, commonly used for
      preventive disinfection and focal disinfection, in hospitals mainly used
      for the disinfection of the environment and medical supplies. It is widely
      used as a biocide in circulating cooling water systems and in the
      sterilization of swimming pool water, in which liquid chlorine pump are
      used to dose the sodium dichloroisocyanurate water solution
    </p>
    <p>
      NEWDOSE measurement dosing pump is currently well used in sodium
      hypochlorite and calcium hypochlorites solvent delivery, such as the
      disinfection of swimming pool and landscape water, pure water
      manufacturing, paper-making industry pulp non-element chlorine bleaching,
      etc. The working mode of NEWDOSE chlorine metering pump includes standard
      manual dosing and automatic dosing system signal control pump, which can
      cooperate with various online instruments and controllers to achieve the
      purpose of precise dosing.
    </p>
  </section>
</template>
 <script>
export default {
  title: "Chlorine Dosing Pump",
}
</script>